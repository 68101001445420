import type { ChangeEvent, FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as R from 'ramda'
import moduleStyles from '../../Cart.module.scss'
import clsx from 'clsx'
import CoLoginBannerLarge from '@components/cart/CoLoginBannerLarge'
import { useTranslate } from '@hooks/useTranslation'
import FooterPanel from '@components/cart/FooterPanel'
import { useCartState } from '@components/cart/State/state'
import type { CartStep as CartStepEnum } from '@components/cart/Stepper/Stepper'
import {
  CwsAccordion,
  CwsAccordionItem,
  CwsAccordionItemBody,
  CwsAccordionItemHeader,
  CwsCheckbox,
  CwsDivider,
  CwsGridCol,
  CwsGridRow,
  CwsH2,
  CwsH4,
  CwsP,
} from '@components/cws'
import { getFormattedPrice } from '@utils/price'
import { getAllTotalsByClassName, getGiftCouponsAppliedTotal, getOrderTotalByClassName } from '@utils/order'
import { getCurrencyOptByLanguageId, useCurrency } from '@hooks/useCurrency'
import PriceLine from '../../../product/PriceLine'
import { PaymentMethods } from '@components/cart/payment/PaymentMethods/PaymentMethods'
import { useSaveOrder } from '@hooks/useSaveOrder'
import { useAppState } from '@context/state'
import GiftOrPromoCode from '@components/cart/GiftOrPromoCode'
import CoLoginBanner from '@components/cart/CoLoginBanner'
import { useCart } from '@hooks/useCart'
import { includesAlcohol } from '@utils/cart-utls'
import ClubOneSlider from '@components/cart/payment/ClubOneSlider/ClubOneSlider'
import useSWR from 'swr'
import Separator from '@components/cart/payment/Separator'
import { calculateCoMax } from '@api/clubone/utils'
import { useRouter } from 'next/router'
import { useInitialData } from '@hooks/useInitialData'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { localStorageHelper } from '@utils/localStorageHelper'
import PromoAndDiscountCodes from '@components/cart/payment/PromoAndDiscountCodes'
import { useCartGaEvents } from '@hooks/useCartGaEvents'
import type { OrderTotal } from '@model/order/OrderTotal'
import { useMaxCoPoint } from '@hooks/useMaxCoPoint'
import { useOrderNormalPricesSum } from '@hooks/useOrderNormalPricesSum'
import { getPriceValueWithCurrency } from '@utils/currency'
import { useMediaLess } from '@ui/hooks/useMediaLess'

export const getUniqueVatLines = (lines: OrderTotal[]): OrderTotal[] => {
  // Step 1: Group by title
  const groupedByTitle: Record<string, OrderTotal[]> = R.groupBy(R.prop('title'), lines)

  // Step 2: Sum values within each group
  const summedValues: Record<string, OrderTotal> = R.map(
    (items) =>
      items.reduce(
        (acc, item) => ({
          ...acc,
          value: acc.value + item.value,
        }),
        { ...items[0], value: 0 }
      ), // Copy all properties from the first item in the group
    groupedByTitle
  )

  // Step 3: Extract values as an array
  return R.values(summedValues)
}
// export const getUniqueVatLines: (lines: OrderTotal[]) => OrderTotal[] = R.compose(R.uniqBy(R.prop('title')))

export const PaymentMain: FC = () => {
  const { translate } = useTranslate()
  const {
    state: { customer },
  } = useAppState()
  const {
    dispatch,
    state: { order, discountCoupons, deliveryMethodsByCountry, deliveryCountry, redeemPoints, ...state },
  } = useCartState()
  const [isDiscountOpen, setIsDiscountOpen] = useState(true)
  const isMobile = useMediaLess(1024)
  const [isClubOnePointOpen, setIsClubOnePointOpen] = useState(true)
  const { currency } = useCurrency()
  const { beginCheckoutGaEvent } = useCartGaEvents()
  const { locale } = useRouter()
  const { currencies, allPromotions } = useInitialData()
  const languageId = KK_LANG_IDS[locale as Locale]
  const currencyOptions = getCurrencyOptByLanguageId(languageId, currencies)
  const { addPaymentInfoGaEvent } = useCartGaEvents()
  const { onProceedClick, saving, orderSaveError } = useSaveOrder()
  const { maxCoPointsToSpend } = useMaxCoPoint()
  useEffect(() => {
    dispatch({ type: 'setOrderSaveError', payload: orderSaveError })
  }, [orderSaveError, dispatch])

  const otGetter = useMemo(() => getOrderTotalByClassName(order?.orderTotals || []), [order?.orderTotals])
  // const totalTax = useMemo(() => getOrderTotalTax(order?.orderTotals || []), [order?.orderTotals])
  const otGetterAll = useMemo(() => getAllTotalsByClassName(order?.orderTotals || []), [order?.orderTotals])
  const uniqueVatLines = useMemo(() => getUniqueVatLines(otGetterAll('ot_tax')), [otGetterAll])

  const regularPricesSum = useOrderNormalPricesSum(order?.orderProducts || [])

  const setStep = useCallback((step: CartStepEnum) => dispatch({ type: 'setStep', payload: step }), [dispatch])
  const completeStep = useCallback(
    (step: CartStepEnum) => dispatch({ type: 'completeStep', payload: step }),
    [dispatch]
  )
  const getFirstCostSafely = R.pathOr(0, ['quote', 'cost'])
  const { cart: cartItems = [] } = useCart('shopping cart')
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)

  const orderTotalPrice = useMemo(() => {
    if (!order) return 0
    const orderTotalPriceRaw = getGiftCouponsAppliedTotal(order).totalValueAfterCoupons ?? order.totalIncTax
    return orderTotalPriceRaw < 0 ? 0 : orderTotalPriceRaw
  }, [order])

  const termsText = useMemo(() => {
    if (includesAlcohol(cartItems)) return translate('basket.shipping.accept.terms.alcohol')
    return translate('w2.cart.terms.and.conditions')
  }, [translate, cartItems])

  const unableToProceed = useMemo(() => {
    if (state.outOfStockProducts.length) {
      console.warn('Out of stock items in basket')
      return true
    }

    return !termsAccepted || saving
  }, [saving, termsAccepted, state.outOfStockProducts])

  const youSaveValue = otGetter('ot_saving')?.value || 0

  const selectedDeliveryMethod = useMemo(
    () => deliveryMethodsByCountry[deliveryCountry],
    [deliveryMethodsByCountry, deliveryCountry]
  )

  return (
    <section className={clsx(moduleStyles.container)}>
      {!Boolean(customer) && <CoLoginBannerLarge />}
      <CwsAccordion size="medium">
        <CwsAccordionItem
          open={isDiscountOpen}
          onClick={() => setIsDiscountOpen((p) => !p)}
          id="send-to-a-friend"
          className="px-4"
        >
          <CwsAccordionItemHeader label={translate('w2.cart.discounts.header')} />
          <CwsAccordionItemBody>
            <GiftOrPromoCode hideHeading />
            <PromoAndDiscountCodes otGetter={otGetter} otGetterAll={otGetterAll} order={order} />
          </CwsAccordionItemBody>
        </CwsAccordionItem>
        <CwsAccordionItem
          open={isClubOnePointOpen}
          onClick={() => setIsClubOnePointOpen((p) => !p)}
          id="club-one-points-slider"
          className="px-4"
        >
          <CwsAccordionItemHeader label={translate('cart.copoints.title')} />
          <CwsAccordionItemBody>
            <CwsGridRow noGutter verticalAlign="center" justifyContent="space-between">
              <CwsGridCol col="12">
                <CwsP className="cws-mb-0" size="small">
                  {translate('cart.copoints.hint')}
                </CwsP>
              </CwsGridCol>
              <CwsGridCol col="12" md="12" lg="5">
                {!Boolean(customer) || !customer?.taxIdentifier ? (
                  <CoLoginBanner onPaymentView />
                ) : (
                  <ClubOneSlider availablePoints={maxCoPointsToSpend || 0} />
                )}
              </CwsGridCol>
              <CwsGridCol col="12" md="12" lg="1">
                <Separator isMobile={isMobile} />
              </CwsGridCol>
              <CwsGridCol col="12" md="12" lg="5">
                <PriceLine
                  label={`${translate('cart.info.total')}:`}
                  value={getPriceValueWithCurrency(regularPricesSum, currency, currencies)}
                  size="md"
                  labelClassName="text-sm"
                  valueColor={'midnight'}
                />
                {otGetter('ot_redeem_points')?.value && (
                  <CwsP textAlign="right" size="p" weight="500" hideSpacing>
                    {translate('w2.cart.co.points.used', {
                      points: parseFloat(otGetter('ot_redeem_points')?.title || `${redeemPoints}`),
                    })}

                    {/*{getFormattedPrice(otGetter('ot_redeem_points')?.value || 0, currency)}*/}
                  </CwsP>
                )}
              </CwsGridCol>
            </CwsGridRow>
          </CwsAccordionItemBody>
        </CwsAccordionItem>
      </CwsAccordion>
      <hr className="text-medium-grey my-2" />
      {orderTotalPrice > 0 && (
        <div className={moduleStyles.section}>
          <PaymentMethods ssoToken={customer?.ssoToken} />
        </div>
      )}
      <hr className="text-medium-grey my-2" />
      <div className={moduleStyles.sectionSmallGap}>
        {Boolean(youSaveValue) && (
          <PriceLine
            label={translate('product.detail.price.yousave')}
            value={youSaveValue}
            size="sm"
            labelClassName="text-sm"
            labelColor="notice"
            valueColor={'notice'}
          />
        )}
        {Boolean(redeemPoints) && (
          <PriceLine
            label={translate('cart.confirmation.pointsRedeemed')}
            value={redeemPoints}
            size="sm"
            labelClassName="text-sm"
            valueColor={'midnight'}
            coPoints
          />
        )}
        {otGetter('ot_shipping')?.value === 0 ? (
          <div className="flex justify-between">
            <CwsP size="small">{translate('product.detail.description.delivery')}</CwsP>
            <CwsP size="small">{translate('basket.delivery.free')}</CwsP>
          </div>
        ) : (
          <PriceLine
            label={translate('product.detail.description.delivery')}
            value={otGetter('ot_shipping')?.value || 0}
            size="sm"
            labelClassName="text-sm"
            valueColor={'midnight'}
          />
        )}

        <CwsDivider color={'midnight'} spacing="s" weight="2px" />
        <div className="flex flex-col space-y-sm">
          <CwsH4 textAlign="left">{translate('cart.confirmation.total')}</CwsH4>
          <CwsH2 textAlign="right" hideSpacing>
            {getFormattedPrice(orderTotalPrice, currency)}
          </CwsH2>
        </div>
        {orderTotalPrice > 0 &&
          Boolean(uniqueVatLines.length) &&
          uniqueVatLines.map((vatOt) => {
            if (!vatOt?.value) return null
            return (
              <PriceLine
                key={vatOt.value}
                label={translate(vatOt?.title ?? '')}
                value={vatOt?.value ?? 0}
                size="sm"
                labelClassName="text-sm"
                valueColor={'midnight'}
              />
            )
          })}
      </div>
      <CwsDivider color="medium-grey" />
      <div className={clsx(moduleStyles.section)}>
        <CwsGridRow noGutter verticalAlign="center">
          <CwsGridCol col="1">
            <CwsCheckbox
              className=""
              checked={termsAccepted}
              id="terms-check"
              inline
              onChange={(e: ChangeEvent<HTMLInputElement>) => setTermsAccepted(e.target.checked)}
            />
          </CwsGridCol>
          {/*The reason to use the component = impossible to set html to CwsCheckbox label*/}
          <CwsGridCol col="fluid">
            <CwsP weight="500" className="pt-2" dangerouslySetInnerHTML={{ __html: termsText }}></CwsP>
          </CwsGridCol>
        </CwsGridRow>
      </div>
      <FooterPanel
        previousStepHandler={() => {
          setStep('delivery')
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
        nextStepHandler={async () => {
          await onProceedClick()
          addPaymentInfoGaEvent()
          completeStep('payment')
        }}
        nextStepTitle={translate('w2.cart.button.pay')}
        previousStepTitle={translate('w2.cart.back.to.delivery')}
        nextStepBlocked={unableToProceed}
      />
    </section>
  )
}
